.offer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:flex-start;
    margin-left: 3em;
}
.offer h1{
    font-size: 3.em;
    align-items: left;
}
.offer>p{
     initial-letter: 2;
    margin: 2em 5em 2em 0;
    font-size: 1.3em;
    text-align: left;
}
.ofert-container{
    background-color: var(--blue-theme);
    height: 300px;
    border-radius: 2em;
    padding: 1em 2em 2em 2em;
    color: #fff;
    width: 25em;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.1em;
    
}
.ofert-container p{
    height: 100%;
    text-align: left;
}
.ofert-container p b{
    font-size: 1.15em;
}
.ofert-container-more{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.test{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    transition: max-height 0.3s ease;
    /* margin-left: 4em; */
}


.offer h3,h2{
    /* color: var(--font-gray); */
    color:#fff;
    /* margin-right: 2em; */

}

.offer-end{
    margin-top: 4em;
    background-color: var(--gray-theme);
    padding: 2em;
    /* width: 100%; */
}

@media (max-width: 768px) {
    .offer{
        margin-left:1.5em;
    }
    .offer>p{
        margin: 2em 1.5em 2em 0;
        padding: 0 0.7em;
        font-size: 1.2em;
        text-align: left;
    }
    .ofert-container{
        width: 70vw;
        height: 55vh;
        font-size: 1em;
    }
    .offer-card{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
    }
      
      .test.expanded {
        max-height: 1000px; /* Large value to show the full content */
      }
      
      .toggle-icon {
        cursor: pointer;
        text-align: center;
        margin-top: 10px;
        font-size: 24px;
        user-select: none;
      }
      
      .toggle-icon:hover {
        color: #007BFF; /* Change color on hover */
      }
      
}

@media (max-width: 380px) {
    .ofert-container{
        height: 65vh;
        font-size: 0.95em;
    }
}