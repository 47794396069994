
.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2); 
  }
  
  .navbar-logo {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .navbar-logo img {
    height: 60px;
    width: 60px;
  }

  .navbar-logo-mobile {
    display: flex;
    align-items: center;
  }
  .navbar-logo-mobile img {
    height: 90px;
    width: 90px;
  }
  .navbar-icon{
    display: none;
  }
  .navbar-menu {
    list-style: none;
    display: flex;
    gap: 40px;
    margin: 0;
    padding: 0;
  }
  
  .navbar-item a {
    text-decoration: none;
    color: #333;
    font-size: 1.2em;
    transition: color 0.3s;
  }
  
  .navbar-item a:hover {
    color: var(--blue-theme);
  }
  
  .navbar-item-mobile .navbar-item{
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  .btn {
    background-color: var(--blue-theme);
    padding: 0.7em;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    border: none;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }
  
  .btn a {
    text-decoration: none;
    color: #fff;
    transition: color 0.3s;
    font-weight: inherit;
    font-size: inherit;
  }
  .buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 2em;
  }
  .close-menu-btn{
    position: absolute;
    right: 25px;
    top: 25px;
  }
  .close-menu-btn.open{
    position: fixed;
  }
  .btn-mobile {
    display:none;
    background-color: var(--blue-theme);
    padding: 0.7em;
    border-radius: 5px;
    border: none;
  }
  
  .btn-mobile a {
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    transition: color 0.3s;
  }
  .navbar-menu-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .navbar-text{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;  
    font-weight: 900;
    font-size: 2em;
    color: var(--blue-theme);
    width: 100%;
    height: 100%;
  }
  
  @media (max-width: 900px) {
    .navbar-menu {
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      background-color: #fff;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 20px;
      padding-top: 3em;
      transform: translateX(100%);
      transition: transform 0.3s ease-in-out;
      z-index: 999;
    }
  
    .navbar-menu.open {
      transform: translateX(0%);
    }
  
    .navbar-item a {
      font-size: 24px;
    }
  
    .navbar-icon {
      display: block;
      font-size: 24px;
      cursor: pointer;
      z-index: 1000;
    }
  
    .navbar-icon svg {
      color: #333;
    }
  
    .btn {
      display: none; 
    }
    .btn-mobile {
        display: block; 
      }
  }
  
