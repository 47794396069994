.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin-top: 10em;
}

.login-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: var(--gray-theme);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: white; /* Set text color inside box to white */
}

.login-box h1 {
  color: #fff;
}

.login-input {
  margin-bottom: 16px;
}

.login-input .MuiInputBase-input, /* Text inside input */
.login-input .MuiInputLabel-root {
  color: #fff; /* Set input text and label text color to white */
}

.login-input .MuiOutlinedInput-root {
  & fieldset {
      border-color: #fff; /* Set border color to white */
  }
  &:hover fieldset {
      border-color: #fff; /* Ensure hover border is also white */
  }
  &.Mui-focused fieldset {
      border-color: #fff; /* Set focused border color to white */
  }
}

.login-error {
  margin-top: 8px;
  width: 100%;
  color: #fff; /* Set error text color to white */
  background-color: rgba(255, 0, 0, 0.1); /* Slightly transparent red background */
  border-color: white; /* Ensure border color of the alert is white */
}

.login-button {
  margin-top: 2em !important;
  background-color: #3f51b5;
  color: white;
}

.login-button:hover {
  background-color: #303f9f;
}
