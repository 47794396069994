.kontakt-content{
    margin: 3em;
    height:100%;
    flex: 1;

}
.kontakt-content p {
    text-align: left;
}
.kontakt{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}