.icon{
  color: var(--font-gray);
}
.img-container {
    position: relative;
    width: 100%;
    max-width: 100%; 
  }
  .img-container img{
    max-height: 500px;
    overflow: hidden; 
  }
  .img-main, .img-main-mobile {
    object-fit: cover; /* Covers the container while maintaining aspect ratio */
    object-position: center; /* Center the image */
    overflow: hidden; 
  }
  
  
  .img-main {
    width: 100%;
    height: auto; 
    display: block; 
  }
  .img-main-mobile{
    width: 100%;height: auto;
    display: block;
  }
  .content-text{
    display:flex;
    align-items: center;
  }
  .content-text h1{
    font-size: 3.9rem; 
    font-weight: bold;
    padding-left: 2em;
  }
  /* .left{
    padding-right: 0 !important
  } */
  .right{
    margin-right: 4em;
  }
  .content-text-fill {
    /* background-color: #255594; */
    flex: 0.3  ;
    /* width: 40%; */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;
  }
  .content-text-fill img{
    width: 60%;
    height: auto;
    max-width: 300px;

  }
  .content-text-fill-icon{
    width: 50%;
    height: auto;
    max-width: 250px;
  }
  .content-text-main {
    flex: 0.7;
    /* width: 60%; */
    height: 100%;
    padding: 3em 5em 3em 5em;
    /* background-color: red; */
  }
  .content-text-main p{
    padding-left: 4em;
    padding-right: 4em;
  }
  
  .content-phone{
    background-color: var(--gray-theme);
    padding-bottom: 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
  }
  .content-phone h1{
    font-size: 2.5em;
    color:#fff;
  }
  .content-phone-nr{
    display: flex;
    gap: 1em;
    justify-content: center;
    align-items: center ;
  }
  .overlay-text {
    display: inline-block;
    position: absolute;
    bottom: 20px; 
    left: 20px; 
    color: white; 
    font-size: 3.8rem; 
    font-weight: bold;
    
    padding: 10px 20px; 
    border-radius: 5px; 
  }
  .overlay-text-small{
    display: inline-block;
    position: absolute;
    top: 6em; 
    left: 3em; 
    color: white; 
    font-size: 1.5rem; 
    font-weight: 500;
    max-width:40vw;
    padding: 10px 20px; 
    border-radius: 5px;
    height: 100%;
  }
  .overlay-text-mobile {
        display: none;
        
        font-size:  2em;
        font-weight: bold;
        padding: 0;
        text-align: left;
        color: #255594;

  }
  .mobile-icon{
    display: block;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .title {
    font-size: 3.9rem;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .paragraph {
    /* font-size: 1rem; */
    /* color: #555; */
    line-height: 1.6;
    margin-bottom: 20px;
    text-align: justify;
  }
  .content-container{
    display: flex;
    /* gap:3em; */
    justify-content: center;
    align-items: flex-start;
  }
  .content-container div{
    width: calc(50vw - 11em);
    padding: 2em 4em;
  }
  .content-phone a{
    font-size: 2.3em;
    color: #fff;
    font-weight: 600;
    text-decoration: none !important;
  }
  
  @media (max-width: 1150px) {
    .content-text{
      flex-direction: column;
    }
    .content-container{
      flex-direction: column;
    }
    .content-container div{
      width: 70vw;
    }
    .mobile-icon{
      display: none;
    }
    .content-text-main {
      padding: 1em 5em 1em 5em;
    }
  }
  @media (max-width: 1050px) {
    .overlay-text-small{
      top: 4em; 
      left: 3em; 
    }
  }
  @media (max-width: 768px) {
    .overlay-text-mobile {
      background-color: var(--blue-theme);
      color: #fff;
      font-size: 3em;
      text-align: center  ;
      
    }
    .content-container div{
      padding: 1em 0;
      width: 85vw;
      font-size: 1em;
    }
    .content-text h1 {
      font-size: 3em;
    }
    .content-text-main {
      padding: 1em;
    }
    .content-text-main p{
      padding: 0 1em 0 1em;
    }
    .overlay-text-mobile {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .img-main {
      display: none;
    }
    .content-container h1{
      font-size: 2.8em;
    }
    .img-main-mobile{
      max-height: 50vh;
      object-fit:cover;

    }
    .paragraph{
      text-align: left;
    }
    .overlay-text-small{
      font-size: 1.3em;
      top: 0;
      left: 0;
      max-width: 100vw;
      font-weight: 500;
      padding: 0 2em;
      display: flex;align-items: center;
      justify-content: center;
    }
    .content-phone h1{
      font-size: 1.8em;
    }
    .content-phone a{
      font-size: 2em;
    }
    
  }
    
  @media (max-width: 350px) {
    .overlay-text{
      font-size: 2.5em;
    }
  }
  