@import "react-alice-carousel/lib/alice-carousel.css";
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --backgroud-color: #A8AAAD;
  --blue-theme: #1761A0;
  --footer-gray: #D9D9D9;
  --font-gray: #7b7b7b;;
  --gray-theme: #171717;
}