.zdjecia{
  min-height: 100vh;
}
.image-list-inside{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.3em;
}
.image-list-inside p {
    font-size: 0.5em;
}
.modal {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
  }
  
  .modal-photo {
    width: 100%;
    height: 90%;
    object-fit: contain;
  }
  .modal-video-player{
    max-width: 90%;
    height: 100%;
    object-fit: contain;
  }
  .modal-video {
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-controls {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
    padding: 0 20px;
  }
  
  .modal-prev-button,
  .modal-next-button {
    background-color: rgba(0, 0, 0, 0.5);
    color: white !important;
  }
  .modal-close-container{
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .modal-close-button {
    background-color: rgba(0, 0, 0, 0.5);
    color: white !important;
  }
  
  .photo-header{
    display: flex;
    flex-direction: row;
    padding: 2em;
    justify-content: flex-start;

  }
  
  .photo-header h1{
    flex: 1;
  }
  .photo-header-description{
    flex: 2;
    width: 100%;
    display: flex;
    gap: 2em;
    align-items: center;
    justify-content: center;
    padding-right: 2em;
  }
  .input-description{
    padding: 1em;
    width: 40vw;
    height: 10vh;
  }
  .photo-header-description-text{
    display: flex;
    justify-self: center;
    align-items: center;
    gap: 2em;
  }
  .photo-header-description-text p{
    flex: 10;
  }
  .dragndrop{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .photo-header-description-text-icon{
    width: 100px;
    flex: 1;
  }

  .dropzone-component{
    margin-top:2em !important;
    margin-bottom:2em !important;
    display: flex;
    flex-direction:  column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .dropzone-component h2{
    color: #000;
  }

  .dropzone-component {
    margin-bottom: 20px;
  }
  
  .dropzone-component h2 {
    margin-bottom: 10px;
  }
  
  .dropzone-component form {
    margin-bottom: 20px;
  }
  
  .dropzone-component label {
    display: block;
    margin-bottom: 5px;
  }
  
  .dropzone-component input[type="text"] {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .dragndrop{
    margin-bottom: 10px;
  }
  
  .dragndrop div {
    width: 50vw;
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
    cursor: pointer;
    padding-right: 2em;
    padding-left: 2em;
  }
  .dragndrop-dashed{
    border: 2px dashed #ccc;
    border-radius: 10px;
  }
  
  .dragndrop div.active {
    background-color: #f0f0f0;
  }
  
  .dropzone-component button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .dropzone-component button.disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .uploading-progress {
    margin-bottom: 20px;
  }
  
  .uploading-progress p {
    margin-bottom: 5px;
  }
  
  .progress-bar-container {
    width: 100%;
    background-color: #e0e0e0;
    height: 10px;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .progress-bar {
    height: 100%;
    background-color: #76c7c0;
  }
  
  .upload-success {
    margin-top: 20px;
    text-align: center;
  }

  .gallery-img-container {
    position: relative;
    height: 300px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
  .gallery-img-container img {
    min-height:300px; 
    width: 100%; 
    object-fit: cover; 
  }
  
  .gallery-img-container-close {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 2;
    color: red;
    font-size: 24px;
    cursor: pointer;
  }
  .gallery-img-container-setup {
    position: absolute;
    bottom: 5px;
    right: 5px;
    z-index: 2;
    color: greenyellow;
    font-size: 24px;
    cursor: pointer;
  }
  .gallery-img-control-panel{
    display: flex;
    flex-direction: column;
  }
  
.photo-menu{
  margin-bottom: 10em;
}

.media-item-video{
    object-fit: 'cover';
    object-position: 'center';
    max-height: 100%;
    width: auto;
}
.gallery-video-container{
  display: grid;
  grid-template-columns: repeat(3, 1fr);

}
.gallery-img-container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.legend{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-left: 2em;
  gap:0.8em;

}
.legend-row{
  display: flex;
  align-items: center;
  gap:0.4em;
}

@media (max-width: 1200px) {
  .gallery-video-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    
  }

}

@media (max-width: 768px) {
  .photo-header{
    flex-direction: column;
  }
  .gallery-video-container{
    display: grid;
    grid-template-columns: 1fr;
  }
  .legend{
    flex-direction: column;
    gap: 0em;
    font-size: 1em;
  }
}
